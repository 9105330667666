import axios from "axios";
let BASE_URL = "/api/nb";
if (import.meta.env.MODE === "development") {
	BASE_URL = "http://localhost:5875/api/nb";
}

export default axios.create({
	baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
	baseURL: BASE_URL,
	headers: { "Content-Type": "application/json" },
	withCredentials: true,
});
