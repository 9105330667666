import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductsTable from "./ProductsTable";

export default function ProductsPage() {
	const [products, setProducts] = useState<any[]>([]);
	const { search } = useLocation();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	const queryParams = useMemo(() => search, [search]);

	const tab = useMemo(() => {
		const params = new URLSearchParams(queryParams);
		if (!params.has("tab")) return "overview";
		return params.get("tab");
	}, [queryParams]);

	function handleTabChange(tab: string) {
		const params = new URLSearchParams(queryParams);
		params.set("tab", tab);
		navigate(`?${params.toString()}`, { replace: true });
	}

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		getProducts(isMounted, controller);

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	async function getProducts(
		isMounted = true,
		controller = new AbortController(),
	) {
		try {
			const response = await axiosPrivate.get("/auth/products", {
				signal: controller.signal,
			});

			isMounted && setProducts(response.data.products);
		} catch (err) {
			console.error(err);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	async function deleteProduct(
		id: string,
		isMounted = true,
		controller = new AbortController(),
	) {
		const isConfirmed = window.confirm(
			"Είστε σίγουροι ότι θέλετε να διαγράψετε το προϊόν;",
		);
		if (!isConfirmed) return;

		try {
			const response = await axiosPrivate.delete(`/auth/products/${id}`, {
				signal: controller.signal,
			});

			isMounted && setProducts(response.data.data);
		} catch (err) {
			console.error(err);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	return (
		<>
			<div className="flex-1 space-y-4 p-8 pt-6">
				<Tabs
					defaultValue="overview"
					value={tab!}
					onValueChange={(value) => handleTabChange(value)}
					className="space-y-4"
				>
					<TabsList>
						<TabsTrigger value="overview">Σύνοψη</TabsTrigger>
					</TabsList>
					{/* {appStore.user.role === "superAdmin" && (
						<AddProduct getProducts={getProducts} />
					)} */}
					<TabsContent value="overview" className="space-y-4 tabs-cont">
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-8">
							<ProductsTable
								products={products}
								deleteProduct={deleteProduct}
							/>
						</div>
					</TabsContent>
					<TabsContent value="details" className="space-y-4">
						{/* Add detailed product information here if needed */}
					</TabsContent>
				</Tabs>
			</div>
		</>
	);
}
