import { UserAuthForm } from "./components/user-auth-form";

export default function AuthenticationPage() {
	return (
		<>
			<div className="container relative  h-[800px] flex-col items-center justify-center grid lg:max-w-none  lg:px-0">
				<div className="lg:p-8">
					<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
						<div className="flex flex-col space-y-2 text-center">
							<h1 className="text-2xl font-semibold tracking-tight">
								AlmaProperty Login
							</h1>
						</div>
						<UserAuthForm />
					</div>
				</div>
			</div>
		</>
	);
}
