import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { formatDate } from "@/utils";

import { useNavigate, useParams } from "react-router-dom";

const ProductCard = ({ product }) => {
	const navigate = useNavigate();
	const { org_id } = useParams();
	return (
		<Card
			onClick={() => {
				navigate(`/org/${org_id}/product/1`);
			}}
			className="cursor-pointer"
		>
			<CardHeader>
				<CardTitle>{"Property"}</CardTitle>
			</CardHeader>
			<CardContent>
				<p className="text-sm text-gray-500">
					Ημ. Δημιουργίας:{" "}
					{formatDate(product.created_at, { simple: true, withTime: true })}
				</p>
				<p className="mt-1">
					<span className="font-semibold">Πακέτο ενεργής συνδρομής:</span>{" "}
					{product?.package_name}
				</p>
				<p className="mt-1">
					<span className="font-semibold">Μέγιστος αριθμός χρηστών:</span>{" "}
					{product?.allowed_users}
				</p>
				<p className="mt-2">
					<span className="font-semibold">Συνολικοί χρήστες:</span>{" "}
					{product?.current_users}
				</p>
				<p className="mt-1">
					<span className="font-semibold">Ενεργοί χρήστες:</span>{" "}
					{product?.active_users}
				</p>

				{/* <Badge color="green" className="mt-3">
					{((product.online_users / product.current_users) * 100).toFixed(1)}%
					Online
				</Badge> */}
			</CardContent>
		</Card>
	);
};
const ProductGrid = ({ product }) => (
	<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
		<ProductCard key={1} product={product} />
	</div>
);

export default ProductGrid;
