import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";

const PersistLogin = () => {
	const [isLoading, setIsLoading] = useState(true);
	const refresh = useRefreshToken();
	const { auth, persist } = useAuth() as { auth: any; persist: boolean };
	// console.log("hi from PersistLogin");
	// console.log(`auth: ${JSON.stringify(auth)}`);
	// console.log(`persist: ${persist}`);

	useEffect(() => {
		let isMounted = true;

		const verifyRefreshToken = async () => {
			try {
				await refresh();
			} catch (err) {
				console.error(err);
			} finally {
				isMounted && setIsLoading(false);
			}
		};

		// persist added here AFTER tutorial video
		// Avoids unwanted call to verifyRefreshToken
		!auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);
		// console.log(`auth: ${JSON.stringify(auth)}`);
		// console.log(`persist: ${persist}`);
		// console.log(`isLoading: ${isLoading}`);

		return () => {
			isMounted = false;
		};
	}, []);

	// useEffect(() => {
	//   console.log(`isLoading: ${isLoading}`)
	//   console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
	// }, [isLoading])

	return <>{!persist ? <Outlet /> : isLoading ? <p></p> : <Outlet />}</>;
};

export default PersistLogin;
