import type { Subscription } from "@/types";
import { axiosPrivate } from "./axios";

interface AddOrganizationParams {
	email: string;
	contactEmail: string;
	afm: string;
	telNumber: string;
	softoneCode: string;
	address: string;
	organization: string;
	allowedUsersNumber: number;
	allowedCreditsNumber: number;
	dateRange: any;
	subscriptions: Subscription[];
	product: string;
	note: string;
}

export const addOrganization = async (
	params: AddOrganizationParams,
	signal?: AbortSignal,
) => {
	const {
		email,
		contactEmail,
		telNumber,
		afm,
		softoneCode,
		address,
		organization,
		allowedUsersNumber,
		allowedCreditsNumber,
		subscriptions,
		dateRange,
		product,
		note,
	} = params;

	return await axiosPrivate.post(
		`/auth/orgs`,
		{
			email,
			contactEmail,
			telNumber,
			afm,
			softoneCode,
			address: address.trim(),
			organization: organization.trim(),
			allowedUsersNumber,
			allowedCreditsNumber,
			dateRange,
			product,
			subscriptions,
			note: note.trim(),
		},
		{
			headers: { "Content-Type": "application/json" },
			withCredentials: true,
			signal,
		},
	);
};

interface GetOrganizationSingleProductParams {
	org_id: string;
	product_id: string;
}

export const getOrganizationSingleProduct = async (
	_params: GetOrganizationSingleProductParams,
	signal?: AbortSignal,
) => {
	return await axiosPrivate.get(`/auth/orgs/`, {
		headers: { "Content-Type": "application/json" },
		withCredentials: true,
		signal,
	});
};
