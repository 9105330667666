import { createContext, useState } from "react";

const AuthContext = createContext({});

import type { ReactNode } from "react";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const localStoragePersist = localStorage.getItem("persist")
		? JSON.parse(localStorage.getItem("persist")!)
		: true;

	const [auth, setAuth] = useState({});
	const [persist, setPersist] = useState(localStoragePersist);

	return (
		<AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
