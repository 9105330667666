import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { formatDate } from "@/utils";
import { List, ListItem, Title } from "@tremor/react";
import { useState } from "react";

const Subscription = ({
	logs,
	fetchLogs,
}: {
	logs: any;
	fetchLogs: (
		options?: {
			users_logs_from?: string;
			users_logs_to?: string;
			credits_logs_from?: string;
			credits_logs_to?: string;
		},
		isMounted?: boolean,
		controller?: AbortController,
	) => Promise<void>;
}) => {
	const [allowedUsersDateRange, setAllowedUsersDateRange] = useState({
		from: "",
		to: "",
	});
	const [allowedCreditsDateRange, setAllowedCreditsDateRange] = useState({
		from: "",
		to: "",
	});

	const handleDateChange = (setter) => (e) => {
		const { name, value } = e.target;
		setter((prev) => {
			const newState = { ...prev, [name]: value };

			// Use the updated state to call fetchLogs
			const updatedOptions = {
				users_logs_from:
					setter === setAllowedUsersDateRange
						? newState.from
						: allowedUsersDateRange.from,
				users_logs_to:
					setter === setAllowedUsersDateRange
						? newState.to
						: allowedUsersDateRange.to,
				credits_logs_from:
					setter === setAllowedCreditsDateRange
						? newState.from
						: allowedCreditsDateRange.from,
				credits_logs_to:
					setter === setAllowedCreditsDateRange
						? newState.to
						: allowedCreditsDateRange.to,
			};

			fetchLogs(updatedOptions);

			return newState;
		});
	};

	return (
		<Card className="col-span-4">
			<CardHeader>
				<CardTitle>Logs Τρέχουσας Συνδρομής</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4 md:divide-x">
					<div className="col-span-2 pl-4">
						<Title>Χρήστες</Title>
						<div className="mb-4 flex space-x-2">
							<input
								type="date"
								name="from"
								value={allowedUsersDateRange.from}
								onChange={handleDateChange(setAllowedUsersDateRange)}
								className="p-2 border rounded"
							/>
							<input
								type="date"
								name="to"
								value={allowedUsersDateRange.to}
								onChange={handleDateChange(setAllowedUsersDateRange)}
								className="p-2 border rounded"
							/>
						</div>
						{logs?.allowedUsersLogs && (
							<List>
								{logs.allowedUsersLogs.map((item, i) =>
									item.users_number ? (
										<ListItem key={`allowed-log-${item.created_at}-${i}`}>
											<span>{item.kind}</span>
											<span>{item.users_number}</span>
											<span className="font-semibold">
												{formatDate(item.created_at)}
											</span>
										</ListItem>
									) : null,
								)}
							</List>
						)}
					</div>
					<div className="col-span-2 pl-4">
						<Title>Credits</Title>
						<div className="mb-4 flex space-x-2">
							<input
								type="date"
								name="from"
								value={allowedCreditsDateRange.from}
								onChange={handleDateChange(setAllowedCreditsDateRange)}
								className="p-2 border rounded"
							/>
							<input
								type="date"
								name="to"
								value={allowedCreditsDateRange.to}
								onChange={handleDateChange(setAllowedCreditsDateRange)}
								className="p-2 border rounded"
							/>
						</div>
						{logs?.allowedCreditsLogs && (
							<List>
								{logs.allowedCreditsLogs.map((item, i) =>
									item.credits ? (
										<ListItem key={`allowed-log-${item.created_at}-${i}`}>
											<span>{item.kind}</span>
											<span>{item.credits}</span>
											<span>{formatDate(item.created_at)}</span>
										</ListItem>
									) : null,
								)}
							</List>
						)}
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default Subscription;
