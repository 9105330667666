import { useNavigate } from "react-router-dom";

import { formatDate } from "@/utils";
import { Badge } from "@tremor/react";
import ReusableTable from "../components/ReusableTable";
const OrgsTable = ({
	orgs,
}: { orgs: any[]; deleteOrg: (id: string) => void }) => {
	const navigate = useNavigate();
	const columns = [
		{
			key: "name",
			header: "Οργανισμός",
			render: (value: string) => (
				<div className="flex items-center space-x-2">
					{/*	<Icon
						icon={TrashIcon}
						className="w-5 h-5 text-red-400 cursor-pointer"
						onClick={(e) => {
							e.stopPropagation();
							deleteOrg(row.id);
						}}
					/> */}
					<div>{value}</div>
				</div>
			),
		},
		{
			key: "status",
			header: "Status",
			render: () => <Badge color="emerald">Ενεργός</Badge>,
		},
		// {
		// 	key: "onlineUsers",
		// 	header: "Online",
		// 	render: (value: number) => (
		// 		<Badge
		// 			color={value > 0 ? "emerald" : "gray"}
		// 			icon={value > 0 ? StatusOnlineIcon : undefined}
		// 		>
		// 			{value}
		// 		</Badge>
		// 	),
		// },
		// {
		// 	key: "users",
		// 	header: "Εν./Επιτρ. Χρήστες",
		// 	render: (_, row: any) => `${row.active_users}/${row.allowed_users}`,
		// },
		// {
		// 	key: "credits",
		// 	header: "Χρ./Επιτρ. Credits",
		// 	render: (_, row: any) => `${row.used_credits}/${row.allowed_credits}`,
		// },
		{
			key: "created_at",
			header: "Ημ/νία Εγγραφής",
			render: (value: string) =>
				formatDate(value, { simple: true, withTime: true }),
		},
		// {
		// 	key: "id",
		// 	header: "",
		// 	render: (value) => (
		// 		<Button
		// 			variant="primary"
		// 			color="red"
		// 			onClick={async (e) => {
		// 				e.stopPropagation();
		// 				deleteOrg(value);
		// 			}}
		// 		>
		// 			Διαγραφή
		// 		</Button>
		// 	),
		// },
	];

	const handleRowClick = (org: any) => {
		navigate(`/org/${org.id}`);
	};

	// console.log("orgs", orgs);
	return (
		<ReusableTable
			data={orgs}
			columns={columns}
			onRowClick={handleRowClick}
			searchableFields={["name"]}
		/>
	);
};

export default OrgsTable;
