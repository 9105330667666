import axios from "../api/axios";
import useAuth from "./useAuth";
import { useNavigate, useLocation } from "react-router-dom";

const REFRESH_URL =
	import.meta.env.MODE !== "development"
		? "https://property.nbalma.gr/api/nb/refresh"
		: "/refresh";

const useRefreshToken = () => {
	const { setAuth } = useAuth() as { setAuth: any };
	let isRefreshing = false;
	const navigate = useNavigate();
	const location = useLocation();

	const refresh = async () => {
		if (isRefreshing) {
			// console.log("Refreshing already in progress");
		}

		isRefreshing = true;
		try {
			const response = await axios.get(REFRESH_URL, {
				withCredentials: true,
			});

			setAuth((prev: any) => ({
				...prev,
				accessToken: response.data.accessToken,
				user: response.data.email,
			}));
			return response.data.accessToken;
		} catch (error) {
			console.error("Error refreshing token:", error);
			navigate("/login", { state: { from: location }, replace: true });
			throw error;
		} finally {
			isRefreshing = false;
		}
	};
	return refresh;
};

export default useRefreshToken;
