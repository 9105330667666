/* eslint-disable @typescript-eslint/no-explicit-any */

import useAxiosPrivate from "@/hooks/useAxiosPrivate";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AddOrganization from "./AddOrganization";
import OrgsTable from "./OrgsTable";

export default function OrgsPage() {
	const [orgs, setOrgs] = useState<any[]>([]);
	const [uploadProgress, setUploadProgress] = useState(0);

	const { search } = useLocation(); // get the search (query string) from the location

	// check if existing search
	const queryParams = useMemo(() => search, [search]);

	const tab = useMemo(() => {
		const params = new URLSearchParams(queryParams);
		if (!params.has("tab")) return "overview";
		return params.get("tab");
	}, [queryParams]);

	function handleTabChange(tab: string) {
		const params = new URLSearchParams(queryParams);
		params.set("tab", tab);
		navigate(`?${params.toString()}`, { replace: true });
	}

	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		getOrgs(isMounted, controller);

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	async function getOrgs(isMounted?: boolean, controller?: AbortController) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			const response = await axiosPrivate.get("/auth/orgs", {
				signal: controller.signal,
			});
			// console.log("Organizations", response.data);

			isMounted && setOrgs(response.data.data);

			return response.data.data;
		} catch (err) {
			console.error(err);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	async function uploadFile(
		file: File,
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			const formData = new FormData();
			formData.append("file", file);
			formData.append("name", file.name);

			const response = await axiosPrivate.post("/auth/users/upload", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				withCredentials: true,
				signal: controller.signal,
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent?.total!,
					);
					setUploadProgress(percentCompleted);
				},
			});

			isMounted && setOrgs(response.data.orgs);
		} catch (err) {
			console.error(err);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	async function deleteOrg(
		id: string,
		isMounted?: boolean,
		controller?: AbortController,
	) {
		const isConfirmed = window.confirm(
			"Είστε σίγουροι ότι θέλετε να διαγράψετε τον οργανισμό;",
		);
		if (!isConfirmed) return;

		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			const response = await axiosPrivate.delete(`/auth/orgs/${id}`, {
				signal: controller.signal,
			});
			if (response.status === 204) {
				isMounted && setOrgs(response?.data);
			}
		} catch (err) {
			console.error(err);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	return (
		<>
			<div className="flex-1 space-y-4 p-8 pt-6">
				{false && (
					<div className="flex items-center justify-start space-y-2">
						{/* input to upload file */}
						<input
							type="file"
							onChange={(e) => e.target.files && uploadFile(e.target.files[0])}
						/>
						<p>Upload progress: {uploadProgress}%</p>
						<h2 className="text-3xl font-bold tracking-tight">Οργανισμοί</h2>

						<div className="flex items-center space-x-2">
							{/* <CalendarDateRangePicker />
              <Button>Download</Button> */}
						</div>
					</div>
				)}

				<Tabs
					defaultValue="overview"
					value={tab!}
					onValueChange={(value) => handleTabChange(value)}
					className="space-y-4"
				>
					<TabsList>
						<TabsTrigger value="overview">Σύνοψη</TabsTrigger>
						{/* <TabsTrigger value="analytics" disabled>
                Analytics
              </TabsTrigger> */}
						{/* <TabsTrigger value="users">Users</TabsTrigger> */}
						{/* <TabsTrigger value="reports" disabled>
                Reports
              </TabsTrigger>
              <TabsTrigger value="notifications" disabled>
                Notifications
              </TabsTrigger> */}
					</TabsList>
					<AddOrganization getOrgs={getOrgs} orgs={orgs} />
					<TabsContent value="overview" className="space-y-4 tabs-cont">
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-8">
							<OrgsTable orgs={orgs} deleteOrg={deleteOrg} />
						</div>
					</TabsContent>
					<TabsContent value="users" className="space-y-4"></TabsContent>
				</Tabs>
			</div>
		</>
	);
}
