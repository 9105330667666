// @ts-nocheck
import { DateTime, Settings } from "luxon";
Settings.defaultLocale = "el";

export function countRecentUsers(array: { created_at: string }[], days = 30) {
	const currentDate = new Date();
	const daysAgo = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		currentDate.getDate() - days,
	);

	let count = 0;
	array?.forEach((item) => {
		const createdDate = new Date(item.created_at);
		if (createdDate >= daysAgo) {
			count++;
		}
	});

	return count;
}

declare global {
	interface DateConstructor {
		createFromMysql(mysql_string: string): Date | null;
	}
}

Date.createFromMysql = (mysql_string: string): Date | null => {
	let t = [];
	let result = null;

	if (typeof mysql_string === "string") {
		t = mysql_string.split(/[- :]/);

		// Convert string elements to numbers
		result = new Date(
			Number(t[0]),
			Number(t[1]) - 1,
			Number(t[2]),
			Number(t[3]) || 0,
			Number(t[4]) || 0,
			Number(t[5]) || 0,
		);
	}

	return result;
};

function formatDateString(input: string) {
	// Parse the input string as a Date object
	const date = new Date(input);

	// Format the date and time components
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	// Construct the formatted string
	return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function formatDate(
	date: string,
	options?: { withTime: boolean; simple: boolean },
) {
	if (!date) return "";
	// default options
	const defaultOptions = {
		withTime: true,
		simple: false,
	};
	options = { ...defaultOptions, ...options };
	if (date.includes("T")) date = formatDateString(date);
	const mysqlDate = Date.createFromMysql(date);
	if (mysqlDate === null) {
		// Handle the null case here
		return "";
	}

	const newDate = new Date(mysqlDate);

	const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({
		minutes: Math.abs(new Date().getTimezoneOffset()),
	});

	if (!options.withTime && !options.simple)
		return formatedDate.toLocaleString(DateTime.DATE_FULL);
	if (options.simple && !options.withTime) {
		// return date in this format: 28.05.2021
		const day = formatedDate.day.toString().padStart(2, "0");
		const month = formatedDate.month.toString().padStart(2, "0");
		return `${day}.${month}.${formatedDate.year}`;
	}
	if (options.simple && options.withTime) {
		// return date in this format: 28.05.2021, 12:00
		const day = formatedDate.day.toString().padStart(2, "0");
		const month = formatedDate.month.toString().padStart(2, "0");
		return `${day}.${month}.${formatedDate.year}, ${formatedDate.toLocaleString(
			{
				hour: "2-digit",
				minute: "2-digit",
				hourCycle: "h23",
			},
		)}`;
	}

	return `${formatedDate.toLocaleString(DateTime.DATE_FULL)}, ${formatedDate.toLocaleString(
		{
			hour: "2-digit",
			minute: "2-digit",
			hourCycle: "h23",
		},
	)}`;
}

/**
 * Validates a Greek Tax Registration Number (AFM).
 * @param afm The AFM to validate.
 * @returns True if the AFM is valid, false otherwise.
 */
export function validateAFM(afm: string): boolean {
	// Check if the AFM is exactly 9 digits
	if (!/^\d{9}$/.test(afm)) {
		return false;
	}

	// Check if it's not all zeros
	if (afm === "000000000") {
		return false;
	}

	// Calculate the check digit
	const sum = afm
		.slice(0, 8)
		.split("")
		.reverse()
		.reduce(
			(acc, digit, index) => acc + Number.parseInt(digit) * 2 ** (index + 1),
			0,
		);

	const checkDigit = (sum % 11) % 10;

	// Compare the calculated check digit with the last digit of the AFM
	return checkDigit === Number.parseInt(afm.charAt(8));
}

export function checkIfDateIsBetween(
	startDate: string,
	endDate: string,
	today?: string,
) {
	if (!today) {
		today = new Date().toISOString().split("T")[0];
	}
	return today >= startDate && today <= endDate;
}
