import * as React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";

import axios from "@/api/axios";
import useAuth from "@/hooks/useAuth";
import useAppStore from "@/store/AppStore"; // Import the AppStore
import { toast } from "sonner";

const LOGIN_URL =
	import.meta.env.MODE !== "development"
		? "https://property.nbalma.gr/api/nb/login"
		: "/login";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}
type useAuthType = {
	setAuth: any;
	persist: boolean;
	setPersist: any;
};

type LoginFormElement = {
	email: HTMLInputElement;
	password: HTMLInputElement;
} & HTMLFormElement;

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
	const { setAuth, persist, setPersist } = useAuth() as useAuthType;
	const appStore = useAppStore() as {
		setUser: (user: { email: string; role: string }) => void;
	}; // Access the AppStore

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	async function onSubmit(event: React.SyntheticEvent) {
		event.preventDefault();
		setIsLoading(true);

		const form = event.target as LoginFormElement;

		try {
			const response = await axios.post(
				LOGIN_URL,
				JSON.stringify({
					email: form.email.value.trim(),
					password: form.password.value.trim(),
				}),
				{
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
				},
			);
			const accessToken = response?.data?.accessToken;
			setAuth({ user: response?.data?.user, accessToken });

			// Save user data in AppStore
			appStore.setUser({
				email: response.data.user,
				role: response.data.role,
			});

			navigate(from, { replace: true });
		} catch (error: any) {
			if (error.status === 401) {
				toast.error("Λάθος στοιχεία εισόδου");
			}
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	function togglePersist() {
		setPersist(!persist);
	}

	React.useEffect(() => {
		localStorage.setItem("persist", JSON.stringify(persist));
	}, [persist]);

	return (
		<div className={cn("grid gap-6", className)} {...props}>
			<form onSubmit={onSubmit}>
				<div className="grid gap-2">
					<div className="grid gap-1">
						<Label className="sr-only" htmlFor="email">
							Email
						</Label>
						<Input
							id="email"
							placeholder="name@example.com"
							type="email"
							autoCapitalize="none"
							autoComplete="email"
							autoCorrect="off"
							disabled={isLoading}
						/>
					</div>
					<div className="grid gap-1">
						<Label className="sr-only" htmlFor="password">
							Password
						</Label>
						<Input
							id="password"
							placeholder="Κωδικός"
							type="password"
							disabled={isLoading}
						/>
					</div>
					<div className="items-top flex space-x-2">
						<Checkbox
							id="terms1"
							checked={persist}
							onCheckedChange={togglePersist}
						/>
						<div className="grid gap-1.5 leading-none">
							<label
								htmlFor="terms1"
								className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
							>
								Παραμείνετε συνδεδεμένοι
							</label>
						</div>
					</div>
					<Button disabled={isLoading}>
						{isLoading && (
							<Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
						)}
						Σύνδεση
					</Button>
				</div>
			</form>
		</div>
	);
}
