import { Button } from "@/components/ui/button";
import useAuth from "@/hooks/useAuth";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { cn } from "@/lib/utils";
import useAppStore from "@/store/AppStore";
import { User, Users } from "lucide-react";
import { NavLink } from "react-router-dom";

export function MainNav({
	className,
	...props
}: React.HTMLAttributes<HTMLElement>) {
	const appStore = useAppStore() as {
		user: {
			email: string;
			role: string;
		};
		clearUser: () => void;
	};
	const axiosPrivate = useAxiosPrivate();
	const { setAuth } = useAuth() as { setAuth: any };

	const handleLogout = async () => {
		const isConfirmed = window.confirm(
			`Είστε σίγουροι ότι θέλετε να αποσυνδεθείτε;`,
		);
		if (!isConfirmed) {
			return;
		}
		try {
			await axiosPrivate.post("/auth/logout");
			setAuth({});
			appStore.clearUser();
			//navigate("/login", { replace: true });
		} catch (error) {
			console.error("Logout failed:", error);
		}
	};

	return (
		<div className="flex flex-row justify-between space-x-4 lg:space-x-6 w-full">
			<nav
				className={cn("flex items-center space-x-4 lg:space-x-6", className)}
				{...props}
			>
				<NavLink
					to="/adduser"
					className={({ isActive }) =>
						`text-sm font-medium ${
							!isActive && "text-muted-foreground"
						} transition-colors hover:text-primary`
					}
				>
					Οργανισμοί
				</NavLink>
				<NavLink
					to="/comments"
					className={({ isActive }) =>
						`text-sm font-medium ${
							!isActive && "text-muted-foreground"
						} transition-colors hover:text-primary`
					}
				>
					Σχόλια
				</NavLink>
				{/* <NavLink
					to="/messaging"
					className={({ isActive }) =>
						`text-sm font-medium ${
							!isActive && "text-muted-foreground"
						} transition-colors hover:text-primary`
					}
				>
					Messaging
				</NavLink> */}
				<NavLink
					to="/products"
					className={({ isActive }) =>
						`text-sm font-medium ${
							!isActive && "text-muted-foreground"
						} transition-colors hover:text-primary`
					}
				>
					Προϊόντα
				</NavLink>
				<NavLink
					to="/transactions"
					className={({ isActive }) =>
						`text-sm font-medium ${
							!isActive && "text-muted-foreground"
						} transition-colors hover:text-primary`
					}
				>
					Καταγραφή ενεργειών
				</NavLink>
			</nav>
			<div className="flex items-center space-x-4 lg:space-x-6">
				<div className="text-sm font-medium text-muted-foreground">
					{appStore.user.email}
				</div>

				<Button
					variant="ghost"
					size="sm"
					onClick={handleLogout}
					className="ml-auto"
				>
					{appStore.user.role === "admin" ? (
						<Users className="mr-2 h-4 w-4" />
					) : (
						<User className="mr-2 h-4 w-4" />
					)}
					Logout
				</Button>
			</div>
		</div>
	);
}
