/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import { SparkAreaExample } from "./Spark";

const Users = ({
	users,
	calls,
	mpUsers,
	mpCalls,
	changeAccountType,
}: {
	users: any;
	calls: any;
	mpUsers: any;
	mpCalls: any;
	changeAccountType: any;
}) => {
	return (
		<>
			<div className="grid gap-4 grid-cols-7">
				<SparkAreaExample
					{...{ users, calls, mpUsers, mpCalls, changeAccountType }}
				/>
				<Card className="col-span-7">
					<CardHeader>
						<CardTitle>Recent Sales</CardTitle>
						<CardDescription>You made 265 sales this month.</CardDescription>
					</CardHeader>
					<CardContent>{/* <RecentSales /> */}</CardContent>
				</Card>
			</div>
		</>
	);
};

export default Users;
