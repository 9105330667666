/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck

import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { useVisibilityChange } from "@uidotdev/usehooks";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { formatDate } from "@/utils";
import {
	Badge,
	Button,
	Flex,
	NumberInput,
	Select,
	SelectItem,
	Text,
	TextInput,
} from "@tremor/react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import AddUser from "./AddOrganization";
import OrganizationLogs from "./OrganizationLogs";
import ProductGrid from "./ProductGrid";
import SingleUser from "./SingleUser";
import Subscription from "./Subscription";
import UsersTable from "./UsersTable";

const kindIndex: { [key: string]: string } = {
	year: "Ετήσια",
	month: "Μηνιαία",
	demo: "14ήμερη",
};

const columns = [
	{ header: "ID", accessor: "id", sortable: true },
	{ header: "Name", accessor: "name" },
	{ header: "Age", accessor: "age" },
	{
		header: "Συνδρομή",
		accessor: "status",
		render: (item) => (
			<Badge color={item.status === "Active" ? "green" : "red"}>
				{item.status}
			</Badge>
		),
	},
];

const customActions = [
	{
		label: "Delete Selected",
		onClick: (selectedIds) => {
			// console.log("Deleting:", selectedIds);
			// Implement delete logic here
		},
		className: "bg-red-500 text-white",
	},
	{
		label: "Export Selected",
		onClick: (selectedIds) => {
			const isConfirmed = window.confirm(
				`Are you sure you want to export ${selectedIds.length} items?`,
			);
			if (!isConfirmed) {
				return; // Stop the function if the user cancels
			}
			// console.log("Exporting:", selectedIds);
			// Implement export logic here
		},
		className: "bg-green-500 text-white",
		allowEmptySelection: true,
	},
];

export default function Organization() {
	const documentVisible = useVisibilityChange();

	const [organization, setOrganization] = useState({} as any);
	const [users, setUsers] = useState<any[]>([]);
	const [subscriptionDetails, setSubscriptionDetails] = useState({} as any);
	const [logs, setLogs] = useState<any>({});
	const [allowedUsersNumber, setAllowedUsersNumber] = useState(0);
	const [allowedCreditsNumber, setAllowedCreditsNumber] = useState(0);
	const [editModeUsers, setEditModeUsers] = useState(false);
	const [editModeCredits, setEditModeCredits] = useState(false);
	const [usersRequestLoading, setUsersRequestLoading] = useState(false);
	const [creditsRequestLoading, setCreditsRequestLoading] = useState(false);
	const [activeSubscription, setActiveSubscription] = useState({} as any);
	const [products, setProducts] = useState([] as any);
	const [product, setProduct] = useState({});

	const [editMode, setEditMode] = useState(false);
	const [editedOrg, setEditedOrg] = useState({
		name: "",
		address: "",
		contact_email: "",
		phone_number: "",
		afm: "",
		customer_s1: "",
	});

	const { org_id } = useParams<{ org_id: string }>();

	const { search } = useLocation(); // get the search (query string) from the location

	// check if existing search
	const queryParams = useMemo(() => search, [search]);

	const tab = useMemo(() => {
		const params = new URLSearchParams(queryParams);
		if (!params.has("tab")) return "overview";
		return params.get("tab");
	}, [queryParams]);

	function handleTabChange(tab: string) {
		const params = new URLSearchParams(queryParams);
		params.set("tab", tab);
		navigate(`?${params.toString()}`, { replace: true });
	}

	const clientUser = useMemo(() => {
		const params = new URLSearchParams(queryParams);
		if (!params.has("user")) return null;
		return params.get("user");
	}, [queryParams]);

	useEffect(() => {
		// console.log("USER:", clientUser);
	}, [clientUser]);

	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate();

	const handleEditToggle = () => {
		if (!editMode) {
			setEditedOrg({
				name: organization?.name || "",
				address: organization?.address || "",
				contact_email: organization?.contact_email || "",
				phone_number: organization?.phone_number || "",
				afm: organization?.afm || "",
				customer_s1: organization?.customer_s1 || "",
			});
		}
		setEditMode(!editMode);
	};

	const handleInputChange = (field: string, value: string) => {
		setEditedOrg((prev) => ({ ...prev, [field]: value }));
	};

	const handleSave = async () => {
		if (!editedOrg.phone_number || !editedOrg.contact_email) {
			toast.error(
				"Τα πεδία 'Email Επικοινωνίας' και 'Τηλέφωνο' είναι υποχρεωτικά.",
			);
			return;
		}
		const isConfirmed = window.confirm(
			"Είστε σίγουροι ότι θέλετε να αποθηκεύσετε τις αλλαγές;",
		);
		if (!isConfirmed) return;

		try {
			const response = await axiosPrivate.patch(
				`/auth/orgs/${org_id}`,
				editedOrg,
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				},
			);
			setOrganization(response.data.org);
			setEditMode(false);
			toast.success("Οι αλλαγές αποθηκεύτηκαν επιτυχώς!");
		} catch (error) {
			console.error(error);
			toast.error("Σφάλμα κατά την αποθήκευση των αλλαγών.");
			//navigate("/login", { state: { from: location }, replace: true });
		}
	};

	useEffect(() => {
		const intervalId: any = null;

		// const handleInterval = () => {
		// 	if (documentVisible) {
		// 		// When document is visible, run every 1 second
		// 		intervalId = setInterval(() => {
		// 			getSingleOrg();
		// 		}, 3000);
		// 	} else {
		// 		// When document is not visible, run every 1 minute
		// 		intervalId = setInterval(() => {
		// 			getSingleOrg();
		// 		}, 60000);
		// 	}
		// };

		// Initialize the interval
		// handleInterval();

		// Clean up
		return () => {
			clearInterval(intervalId);
		};
	}, [documentVisible, tab]);

	async function updateProduct(id: number) {
		setActiveSubscription({ ...activeSubscription, product_id: id });

		const controller = new AbortController();

		try {
			await axiosPrivate.post(
				`/auth/orgs/product/${org_id}`,
				{ product_id: id },
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
					signal: controller?.signal,
				},
			);
			return () => {
				controller.abort();
			};
		} catch (error) {
			console.error(error);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		getSingleOrg(isMounted, controller);

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	async function getProducts(
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			// const response = await axiosPrivate.get("/auth/getPackages", {
			// 	signal: controller.signal,
			// });
			const response = {
				data: {
					packages: [
						{ id: 1, name: "Πακέτο 1" },
						{ id: 2, name: "Πακέτο 2" },
						{ id: 3, name: "Πακέτο 3" },
					],
				},
			};

			isMounted && setProducts(response.data.packages);
		} catch (err) {
			console.error(err);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	async function fetchLogs(
		options?: {},
		isMounted?: boolean,
		controller?: AbortController,
	) {
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}
		// query string
		let query = new URLSearchParams(options).toString();
		query = query ? `?${query}` : "";

		try {
			// const response = await axiosPrivate.get(
			//   `/auth/orgs/logs/${org_id}${query}`,
			//   {
			//     signal: controller.signal,
			//   }
			// );
			const response = {
				data: {
					logs: [
						{
							id: 1,
							user_id: 1,
							action: "Ενημέρωση Οργανισμού",
							created_at: "2021-09-10T10:00:00",
						},
						{
							id: 2,
							user_id: 1,
							action: "Ενημέρωση Οργανισμού",
							created_at: "2021-09-10T10:00:00",
						},
						{
							id: 3,
							user_id: 1,
							action: "Ενημέρωση Οργανισμού",
							created_at: "2021-09-10T10:00:00",
						},
					],
				},
			};

			isMounted && setLogs(response.data.logs);
		} catch (err) {
			console.error(err);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		getSingleOrg(isMounted, controller);

		getProducts(isMounted, controller);
		fetchLogs({}, isMounted, controller);

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	async function getSingleOrg(
		isMounted?: boolean,
		controller?: AbortController,
	) {
		// console.log("org_id fetch", org_id);
		if (isMounted === undefined) {
			isMounted = true;
		}
		if (controller === undefined) {
			controller = new AbortController();
		}

		try {
			const response = await axiosPrivate.get(`/auth/orgs/${org_id}`, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
				signal: controller?.signal,
			});
			const subs = await axiosPrivate.get(`/auth/orgs/${org_id}/products/1`, {
				signal: controller.signal,
			});
			const activeSub = subs.data.subscriptions.filter(
				(e) => e.is_active === 1,
			);
			console.log(activeSub);
			// mock subscription details
			response.data.subscriptionDetails = [
				// {
				// 	id: 1,
				// 	org_id: 1,
				// 	product_id: 1,
				// 	kind: "year",
				// 	from_date: "2021-09-01T00:00:00",
				// 	to_date: "2022-09-01T00:00:00",
				// },
				// {
				// 	id: 2,
				// 	org_id: 1,
				// 	product_id: 2,
				// 	kind: "month",
				// 	from_date: "2021-09-01T00:00:00",
				// 	to_date: "2021-10-01T00:00:00",
				// },
			];
			// console.log("ORG:", response.data);
			isMounted && setOrganization(response.data.org);
			isMounted && setUsers(response.data.users);
			isMounted && setSubscriptionDetails(response.data.subscriptionDetails);
			isMounted && setActiveSubscription(response.data.subscriptionDetails[0]);
			const activeUsers = response.data.users.filter((e) => e.is_active === 1);
			setProduct({
				package_name: activeSub[0].package_name,
				allowed_users: activeSub[0].allowed_users,
				created_at: response.data.org.created_at,
				current_users: response.data.users.length,
				active_users: activeUsers.length,
			});
		} catch (error) {
			console.error(error);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	async function handleUsersSubmit(e: any) {
		e.preventDefault();
		const isConfirmed = window.confirm(
			`Είστε σίγουροι ότι θέλετε να αλλάξετε τον αριθμό των χρηστών σε ${allowedUsersNumber};`,
		);
		if (!isConfirmed) {
			return; // Stop the function if the user cancels
		}
		const isMounted = true;
		const controller = new AbortController();

		try {
			await axiosPrivate.post(
				`/auth/orgs/allowedusers/${org_id}`,
				{ allowedUsersNumber },
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
					signal: controller?.signal,
				},
			);
			setEditModeUsers(false);
			await getSingleOrg(isMounted, controller);
		} catch (error) {
			console.error(error);
			//navigate("/login", { state: { from: location }, replace: true });
		} finally {
			setUsersRequestLoading(false);
		}
	}

	async function handleCreditsSubmit(e: any) {
		e.preventDefault();
		// Show confirmation dialog
		const isConfirmed = window.confirm(
			`Είστε σίγουροι ότι θέλετε να αλλάξετε τον αριθμό των credits σε ${allowedCreditsNumber};`,
		);
		if (!isConfirmed) {
			return; // Stop the function if the user cancels
		}
		const isMounted = true;
		const controller = new AbortController();
		setCreditsRequestLoading(true);

		try {
			await axiosPrivate.post(
				`/auth/orgs/allowedcredits/${org_id}`,
				{ allowedCreditsNumber },
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
					signal: controller?.signal,
				},
			);
			setEditModeCredits(false);
			await getSingleOrg(isMounted, controller);
		} catch (error) {
			console.error(error);
			//navigate("/login", { state: { from: location }, replace: true });
		} finally {
			setCreditsRequestLoading(false);
		}
	}

	async function deleteUser(id: number) {
		const isConfirmed = window.confirm(
			`Είστε σίγουροι ότι θέλετε να διαγράψετε τον χρήστη;`,
		);
		if (!isConfirmed) {
			return; // Stop the function if the user cancels
		}
		const isMounted = true;
		const controller = new AbortController();

		try {
			await axiosPrivate.delete(`/auth/users/${id}`, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
				signal: controller?.signal,
			});
			await getSingleOrg(isMounted, controller);
		} catch (error) {
			console.error(error);
			toast.error(error.response.data);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	async function pauseUser(id: number, is_active: boolean) {
		if (is_active) {
			const isConfirmed = window.confirm(
				`Είστε σίγουροι ότι θέλετε να ενεργοποιήσετε τον χρήστη;`,
			);

			if (!isConfirmed) {
				return; // Stop the function if the user cancels
			}
		}
		if (!is_active) {
			const isConfirmed = window.confirm(
				`Είστε σίγουροι ότι θέλετε να παύσετε τον χρήστη;`,
			);
			if (!isConfirmed) {
				return; // Stop the function if the user cancels
			}
		}

		const isMounted = true;
		const controller = new AbortController();

		try {
			await axiosPrivate.patch(
				`/auth/users/pause/${id}`,
				{ is_active },
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
					signal: controller?.signal,
				},
			);
			await getSingleOrg(isMounted, controller);
		} catch (error) {
			console.error(error);
			//navigate("/login", { state: { from: location }, replace: true });
		}
	}

	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div id="org-header">
				<div className="flex items-center justify-between space-y-2">
					<h2 className="text-3xl font-bold tracking-tight">
						{editMode ? (
							<TextInput
								value={editedOrg.name}
								onChange={(e) => handleInputChange("name", e.target.value)}
								placeholder="Όνομα Οργανισμού"
							/>
						) : (
							organization?.name
						)}
					</h2>
					<Button onClick={handleEditToggle} color={editMode ? "red" : "blue"}>
						{editMode ? "Ακύρωση" : "Επεξεργασία"}
					</Button>
				</div>
				<Card className="mt-4">
					<CardHeader>
						<CardTitle>Στοιχεία Οργανισμού</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="grid grid-cols-2 gap-4">
							<div>
								<h3 className="text-sm font-medium text-gray-500">
									Ημ/νία Εγγραφής
								</h3>
								<p className="text-lg font-semibold text-gray-900">
									{formatDate(organization?.created_at)}
								</p>
							</div>
							<div>
								<h3 className="text-sm font-medium text-gray-500">Διεύθυνση</h3>
								{editMode ? (
									<TextInput
										value={editedOrg.address}
										onChange={(e) =>
											handleInputChange("address", e.target.value)
										}
										placeholder="Διεύθυνση"
									/>
								) : (
									<p className="text-lg font-semibold text-gray-900">
										{organization?.address || "-"}
									</p>
								)}
							</div>
							<div>
								<h3 className="text-sm font-medium text-gray-500">
									Email Επικοινωνίας
								</h3>
								{editMode ? (
									<TextInput
										value={editedOrg.contact_email}
										onChange={(e) =>
											handleInputChange("contact_email", e.target.value)
										}
										placeholder="Email"
										type="email"
									/>
								) : (
									<p className="text-lg font-semibold text-gray-900">
										{organization?.contact_email || "-"}
									</p>
								)}
							</div>
							<div>
								<h3 className="text-sm font-medium text-gray-500">Τηλέφωνο</h3>
								{editMode ? (
									<TextInput
										value={editedOrg.phone_number}
										onChange={(e) =>
											handleInputChange("phone_number", e.target.value)
										}
										placeholder="Τηλέφωνο"
									/>
								) : (
									<p className="text-lg font-semibold text-gray-900">
										{organization?.phone_number || "-"}
									</p>
								)}
							</div>
							<div>
								<h3 className="text-sm font-medium text-gray-500">ΑΦΜ</h3>
								{editMode ? (
									<TextInput
										value={editedOrg.afm}
										onChange={(e) => handleInputChange("afm", e.target.value)}
										placeholder="ΑΦΜ"
									/>
								) : (
									<p className="text-lg font-semibold text-gray-900">
										{organization?.afm || "-"}
									</p>
								)}
							</div>
							<div>
								<h3 className="text-sm font-medium text-gray-500">
									Κωδικός SoftOne
								</h3>
								{editMode ? (
									<TextInput
										value={editedOrg.customer_s1}
										onChange={(e) =>
											handleInputChange("customer_s1", e.target.value)
										}
										placeholder="Κωδικός SoftOne"
									/>
								) : (
									<p className="text-lg font-semibold text-gray-900">
										{organization?.customer_s1 || "-"}
									</p>
								)}
							</div>
						</div>
					</CardContent>
				</Card>
				{editMode && (
					<Button onClick={handleSave} color="green" className="mt-4">
						Αποθήκευση Αλλαγών
					</Button>
				)}
			</div>
			<div id="org-products">
				<h2 className="text-2xl font-bold tracking-tight mb-4">
					Προϊόντα Οργανισμού
				</h2>
				<ProductGrid product={product} />
			</div>

			<Tabs
				defaultValue="overview"
				value={tab!}
				onValueChange={(value) => handleTabChange(value)}
				className="space-y-4"
			>
				<TabsList>
					<TabsTrigger value="overview">Σύνοψη</TabsTrigger>
					{/* <TabsTrigger value="analytics" disabled>
            Analytics
          </TabsTrigger> */}
					{/* <TabsTrigger value="reports" disabled>
            Reports
          </TabsTrigger>
          <TabsTrigger value="notifications" disabled>
            Notifications
			
          </TabsTrigger> */}
					<TabsTrigger value="logs">Καταγραφή ενεργειών οργανισμού</TabsTrigger>
				</TabsList>
				<TabsContent value="overview" className="space-y-4 tabs-cont">
					<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
						<Card>
							<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
								<CardTitle className="text-sm font-medium">Χρήστες</CardTitle>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									className="h-4 w-4 text-muted-foreground"
								>
									<path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
									<circle cx="9" cy="7" r="4" />
									<path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
								</svg>
							</CardHeader>
							<CardContent>
								<div className="text-2xl font-bold">
									{users?.length}
									<span className="text-3xl">
										{organization?.allowed_users}
									</span>
								</div>
								{editModeUsers && (
									<form onSubmit={handleUsersSubmit} className="mt-2">
										<NumberInput
											id="users"
											placeholder="Αριθμός Χρηστών"
											defaultValue={0}
											onValueChange={(value) => {
												setAllowedUsersNumber(value);
											}}
										/>
										<Flex className="mt-2" justifyContent="end">
											<Button
												type="submit"
												color="green"
												className="mt-2"
												loading={usersRequestLoading}
											>
												Αποθήκευση
											</Button>
											<Button
												onClick={() => {
													setEditModeUsers(false);
												}}
												color="red"
												className="mt-2 ml-2"
											>
												Ακύρωση
											</Button>
										</Flex>
									</form>
								)}
								<div className="flex flex-row items-center  mt-2">
									<AddUser getUsers={getSingleOrg} org_id={org_id} />
								</div>
							</CardContent>
						</Card>
					</div>
					{tab === "overview" && !clientUser && (
						<UsersTable
							users={users}
							deleteUser={deleteUser}
							pauseUser={pauseUser}
							orgId={org_id!}
						/>
					)}
					{clientUser && <SingleUser user_id={clientUser} />}
				</TabsContent>
				<TabsContent value="subscription" className="space-y-4">
					<Subscription logs={logs} fetchLogs={fetchLogs} />
				</TabsContent>
				<TabsContent value="logs" className="space-y-4">
					<OrganizationLogs org_id={org_id} />
				</TabsContent>
			</Tabs>
		</div>
	);
}
