import { useEffect, useRef, useState } from "react";

import { Card, CardContent } from "@/components/ui/card";

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { formatDate } from "@/utils";
import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	MinusCircleIcon,
	PauseIcon,
	PlayIcon,
	StatusOfflineIcon,
	StatusOnlineIcon,
} from "@heroicons/react/solid";
import { Flex, Icon } from "@tremor/react";

const UsersTable = ({
	users,
	deleteUser,
	pauseUser,
}: {
	users: any[];
	deleteUser: any;
	orgId: string;
	pauseUser: any;
}) => {
	// console.log("USERS: ", users);
	const prevUsersRef = useRef(users);

	const [highlightedCells, setHighlightedCells] = useState({} as any);

	useEffect(() => {
		const newHighlights = {} as any;
		users?.forEach((user, index) => {
			const prevUser = prevUsersRef.current[index];
			if (!prevUser || user.used_credits !== prevUser.used_credits) {
				newHighlights[`user-${index}`] = true;
			}
		});

		setHighlightedCells(newHighlights);

		const timeoutId = setTimeout(() => setHighlightedCells({}), 3000);

		return () => clearTimeout(timeoutId);
	}, [users]);

	useEffect(() => {
		prevUsersRef.current = users;
	}, [users]);

	const handleDeleteClick = (e: React.MouseEvent, userId: number) => {
		e.stopPropagation(); // Prevent event from bubbling up to the row
		deleteUser(userId);
	};

	const handlePauseClick = (e: React.MouseEvent, userId: number, is_active) => {
		e.stopPropagation(); // Prevent event from bubbling up to the row
		pauseUser(userId, is_active);
	};

	return (
		<Card className="col-span-8">
			<CardContent className="px-0 pb-0">
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead className="max-w-[250px]">Χρήστης</TableHead>
							<TableHead className="max-w-[250px]">Ρόλος</TableHead>
							<TableHead className="max-w-[250px]">Χρ. Credits</TableHead>
							<TableHead className="max-w-[250px]">Λειτ.Σύστημα</TableHead>
							<TableHead className="max-w-[250px]">App Version</TableHead>
							<TableHead className="text-right">Ημερομηνία</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{users?.map(
							(
								org: {
									id: number;
									created_at: string;
									email: string;
									role: string;
									used_credits: number;
									is_active?: number;
									is_registered?: boolean;
									os?: string;
									version?: string;
									online?: boolean;
									inactive?: number;
								},
								index,
							) => (
								<TableRow key={`org-${index}`}>
									<TableCell className="font-medium">
										<Flex justifyContent="start">
											<Icon
												icon={MinusCircleIcon}
												color="red"
												className="button-delete cursor-pointer"
												onClick={(e) => handleDeleteClick(e, org.id)}
												tooltip="Διαγραφή Χρήστη"
											/>
											{org?.is_registered && (
												<Icon
													icon={CheckCircleIcon}
													color="emerald"
													className="button-delete"
													tooltip="Εγγραφή Ολοκληρωμένη"
												/>
											)}
											{!org?.is_registered && (
												<Icon
													icon={ExclamationCircleIcon}
													color="orange"
													className="button-delete"
													tooltip="Εγγραφή Εκρεμμεί"
												/>
											)}
											{org?.is_active === 1 && (
												<Icon
													icon={PlayIcon}
													color="blue"
													className="button-delete"
													tooltip="Ενεργός (Κλικάρετε για παύση)"
													onClick={(e) => handlePauseClick(e, org.id, false)}
												/>
											)}
											{org?.is_active !== 1 && (
												<Icon
													icon={PauseIcon}
													color="red"
													className="button-delete"
													tooltip="Σε παύση (Κλικάρετε για ενεργοποίηση)"
													onClick={(e) => handlePauseClick(e, org.id, true)}
												/>
											)}
											{org?.online ? (
												<Icon
													icon={StatusOnlineIcon}
													color="emerald"
													className="button-delete"
													tooltip="Online"
												/>
											) : (
												<Icon
													icon={StatusOfflineIcon}
													color="gray"
													className="button-delete"
													tooltip="Offline"
												/>
											)}
											{org?.email}
											{org?.inactive === 1 && ` - ανενεργός`}
										</Flex>
									</TableCell>
									<TableCell className="font-medium">{org?.role}</TableCell>
									<TableCell
										className={`font-medium ${
											highlightedCells[`user-${index}`] ? "bg-yellow-200" : ""
										}`}
									>
										{org?.used_credits}
									</TableCell>
									<TableCell className="font-medium">
										{org?.os?.replace("|", " ")}
									</TableCell>
									<TableCell className="font-medium">{org?.version}</TableCell>
									<TableCell className="text-right">
										{formatDate(org.created_at, {
											simple: true,
											withTime: true,
										})}
									</TableCell>
								</TableRow>
							),
						)}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default UsersTable;
