import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { formatDate } from "@/utils";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface Comment {
	id: string;
	message: string;
	email: string;
	created_at: string;
	user_avatar?: string;
}

type SortOption = "newest" | "oldest" | "email";

export default function Comments() {
	const [comments, setComments] = useState<Comment[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [sortBy, setSortBy] = useState<SortOption>("newest");
	const [filterText, setFilterText] = useState("");

	const navigate = useNavigate();
	const location = useLocation();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {
		const controller = new AbortController();

		const getComments = async () => {
			try {
				setLoading(true);
				const result = await axiosPrivate.get<{ comments: Comment[] }>(
					"/auth/comments",
					{
						headers: { "Content-Type": "application/json" },
						withCredentials: true,
						signal: controller.signal,
					},
				);
				setComments(result.data.comments || []);
				setError(null);
			} catch (err) {
				console.error(err);
				setError("Failed to load comments. Please try again later.");
				//navigate("/login", { state: { from: location }, replace: true });
			} finally {
				setLoading(false);
			}
		};

		getComments();

		return () => {
			controller.abort();
		};
	}, [axiosPrivate, location, navigate]);

	const sortedAndFilteredComments = useMemo(() => {
		let result = [...comments];

		// Apply filtering
		if (filterText) {
			result = result.filter(
				(comment) =>
					comment.message.toLowerCase().includes(filterText.toLowerCase()) ||
					comment.email.toLowerCase().includes(filterText.toLowerCase()),
			);
		}

		// Apply sorting
		switch (sortBy) {
			case "newest":
				result.sort(
					(a, b) =>
						new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
				);
				break;
			case "oldest":
				result.sort(
					(a, b) =>
						new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
				);
				break;
			case "email":
				result.sort((a, b) => a.email.localeCompare(b.email));
				break;
		}

		return result;
	}, [comments, sortBy, filterText]);

	return (
		<div className="flex-1 space-y-6 p-8 pt-6">
			<h2 className="text-3xl font-bold tracking-tight">Σχόλια</h2>

			<div className="flex flex-col sm:flex-row gap-4 items-center justify-between">
				<Input
					placeholder="Φιλτράρετε τα σχόλια..."
					value={filterText}
					onChange={(e) => setFilterText(e.target.value)}
					className="max-w-sm"
				/>
				<Select
					value={sortBy}
					onValueChange={(value: SortOption) => setSortBy(value)}
				>
					<SelectTrigger className="w-[180px]">
						<SelectValue placeholder="Sort by" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="newest">Νεότερα</SelectItem>
						<SelectItem value="oldest">Παλαιότερα</SelectItem>
						<SelectItem value="email">Email</SelectItem>
					</SelectContent>
				</Select>
			</div>

			{loading ? (
				<CommentsSkeleton />
			) : error ? (
				<div className="text-red-500">{error}</div>
			) : sortedAndFilteredComments.length === 0 ? (
				<div className="text-gray-500">No comments found.</div>
			) : (
				<div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
					{sortedAndFilteredComments.map((comment) => (
						<CommentCard key={comment.id} comment={comment} />
					))}
				</div>
			)}
		</div>
	);
}

function CommentCard({ comment }: { comment: Comment }) {
	return (
		<Card className="overflow-hidden transition-shadow hover:shadow-lg">
			<CardHeader className="flex flex-row items-center gap-4 pb-2">
				<Avatar>
					<AvatarImage src={comment.user_avatar} alt={comment.email} />
					<AvatarFallback>{comment.email[0].toUpperCase()}</AvatarFallback>
				</Avatar>
				<div>
					<CardTitle className="text-sm font-medium">{comment.email}</CardTitle>
					<p className="text-xs text-muted-foreground">
						{formatDate(comment.created_at)}
					</p>
				</div>
			</CardHeader>
			<CardContent>
				<p className="text-sm">{comment.message}</p>
			</CardContent>
		</Card>
	);
}

function CommentsSkeleton() {
	return (
		<div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
			{[...Array(6)].map((_, index) => (
				<Card key={index} className="overflow-hidden">
					<CardHeader className="flex flex-row items-center gap-4 pb-2">
						<Skeleton className="h-10 w-10 rounded-full" />
						<div className="space-y-2">
							<Skeleton className="h-4 w-[150px]" />
							<Skeleton className="h-3 w-[100px]" />
						</div>
					</CardHeader>
					<CardContent>
						<Skeleton className="h-4 w-full" />
						<Skeleton className="h-4 w-[80%] mt-2" />
					</CardContent>
				</Card>
			))}
		</div>
	);
}
