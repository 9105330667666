import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App.tsx";
import "./index.css";

import { AuthProvider } from "./context/AuthContext.tsx";

const rootElement =
	document.getElementById("root") || document.createElement("div");
ReactDOM.createRoot(rootElement).render(
	<BrowserRouter basename="/nb">
		<AuthProvider>
			<Routes>
				<Route path="/*" element={<App />} />
			</Routes>
		</AuthProvider>
	</BrowserRouter>,
);
