import { Link } from "react-router-dom";

const Hello = () => {
	return (
		<div>
			Hello from inside!
			<Link to="/dashboard">Dashboard</Link>
		</div>
	);
};

export default Hello;
