// a register form for new users that takes in an email and password. It makes a post request to localhost:5789/register
// and if the user is successfully created, it redirects to the login page. Otherwise, it displays an error message.

import type React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Register.css";

function Register() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorText, setErrorText] = useState("");

	const history = useNavigate();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		const response = await fetch("http://localhost:5789/register", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ email, password }),
		});

		const data = await response.json();

		if (data.success) {
			history("/login");
		} else {
			setErrorText(data.message);
		}
	};

	return (
		<div className="Register">
			<h1>Register </h1>
			<form onSubmit={handleSubmit}>
				<label htmlFor="email"> Email </label>
				<input
					id="email"
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>

				<label htmlFor="password"> Password </label>
				<input
					id="password"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>

				<button type="submit"> Register </button>
			</form>
			<p> {errorText} </p>
			<Link to="/login"> Already have an account ? Login </Link>
		</div>
	);
}

export default Register;
