import "./App.css";
import { Route, Routes } from "react-router-dom";
import OrgsPage from "./Pages/AddOrganization/OrgsPage";
import Comments from "./Pages/Comments/Comments";
import DashboardPage from "./Pages/Dashboard/Dashboard";
import Hello from "./Pages/Hello";
import Login from "./Pages/Login/Login";
import Messaging from "./Pages/Messaging/Messaging";
import OrgSingleProduct from "./Pages/Organization/OrgSingleProduct";
import User from "./Pages/Organization/Organization";
import Organization from "./Pages/Organization/Organization";
import ProductsPage from "./Pages/Products/ProductsPage";
import SingleProductPage from "./Pages/Products/SingleProductPage";
import Register from "./Pages/Register";
import Transactions from "./Pages/Transactions/Transactions";
import Layout from "./components/Layout";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/RequireAuth";

function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route path="/register" element={<Register />} />
				<Route path="/login" element={<Login />} />

				<Route element={<PersistLogin />}>
					<Route element={<RequireAuth />}>
						<Route path="/" element={<OrgsPage />} />

						<Route path="/hello" element={<Hello />} />
						<Route path="/dashboard" element={<DashboardPage />} />
						<Route path="/messaging" element={<Messaging />}>
							<Route path=":uuid" element={<Messaging />} />
						</Route>
						<Route path="user" element={<User />}>
							<Route path=":user_id" element={<User />} />
						</Route>
						<Route path="/org">
							<Route index element={<Organization />} />
							<Route path=":org_id" element={<Organization />} />
						</Route>
						<Route path="/org/:org_id/product">
							<Route index element={<OrgSingleProduct />} />
							<Route path=":product_id" element={<OrgSingleProduct />} />
						</Route>
						<Route path="/adduser" element={<OrgsPage />} />
						<Route path="/comments" element={<Comments />} />
						<Route path="/products">
							<Route index element={<ProductsPage />} />
							<Route path=":product_id" element={<SingleProductPage />} />
						</Route>
						<Route path="/transactions" element={<Transactions />} />
					</Route>
				</Route>
			</Route>
		</Routes>
	);
}

export default App;
