import { formatDate } from "@/utils";
import { useNavigate } from "react-router-dom";
import ReusableTable from "../components/ReusableTable";

const ProductsTable = ({
	products,
}: { products: any[]; deleteProduct: (id: string) => void }) => {
	const navigate = useNavigate();
	// console.log("products", products);

	const columns = [
		{
			key: "name",
			header: "Προϊόν",
			render: (value: string) => (
				<div className="flex items-center space-x-2">
					<div>{value}</div>
				</div>
			),
		},
		{ key: "description", header: "Περιγραφή" },

		{
			key: "created_at",
			header: "Ημ/νία Δημιουργίας",
			render: (value: string) =>
				formatDate(value, { simple: true, withTime: true }),
		},
	];

	const handleRowClick = (product: any) => {
		navigate(`/products/${product.id}`);
	};

	return (
		<ReusableTable
			data={products}
			columns={columns}
			onRowClick={handleRowClick}
			searchableFields={["name"]}
		/>
	);
};

export default ProductsTable;
